import type { TypedUseSelectorHook } from 'react-redux';
import { ThunkAction } from "@reduxjs/toolkit"; 
import { Action } from "redux";
import { Provider, useDispatch, useSelector } from 'react-redux';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { configureStore, createSlice } from '@reduxjs/toolkit';
import { SourceType, ChatMessageType } from '../types';
import { APIUrl, getAPIHeaders } from '../interactors/utils';
import { safeTrack } from '../provider/Pendo';
import React from 'react';

export const SearchAPIURL = APIUrl.replace('api', 'enterprise.api');

type GlobalStateType = {
  status: AppStatus;
  conversation: ChatMessageType[];
  sources: SourceType[];
  sessionId: string | null;
};

class RetriableError extends Error {}
class FatalError extends Error {}
export enum AppStatus {
  Idle = 'idle',
  StreamingMessage = 'loading',
  Done = 'done',
  Error = 'error',
}
enum STREAMING_EVENTS {
  SESSION_ID = '[SESSION_ID]',
  USAGE = '[USAGE]',
  SOURCE = '[SOURCE]',
  DONE = '[DONE]',
}

const GLOBAL_STATE: GlobalStateType = {
  status: AppStatus.Idle,
  conversation: [],
  sessionId: null,
  sources: [],
};
const API_HOST = `${SearchAPIURL}v1/enterprise`;

let abortController: AbortController | null = null;
const globalSlice = createSlice({
  name: 'global',
  initialState: GLOBAL_STATE as GlobalStateType,
  reducers: {
    addSource: (state, action) => {
      const source = action.payload.source;
      const rootSource = state.sources.find((s) => s.name === source.name);

      if (rootSource) {
        if (!rootSource.summary.find((summary) => summary === source.summary)) {
          rootSource.summary = [...rootSource.summary, source.summary];
        }
      } else {
        state.sources.push({ ...source, summary: [source.summary] });
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload.sessionId;
    },
    addMessage: (state, action) => {
      state.conversation.push(action.payload.conversation);
    },
    updateMessage: (state, action) => {
      const messageIndex = state.conversation.findIndex((c) => c.id === action.payload.id);

      if (messageIndex !== -1) {
        state.conversation[messageIndex] = {
          ...state.conversation[messageIndex],
          ...action.payload,
        };
      }
    },
    setMessageSource: (state, action) => {
      const message = state.conversation.find((c) => c.id === action.payload.id);

      if (message) {
        message.sources = action.payload.sources.map((sourceName) => state.sources.find((stateSource) => stateSource.name === sourceName)).filter((source) => !!source);
      }
    },
    removeMessage: (state, action) => {
      const messageIndex = state.conversation.findIndex((c) => c.id === action.payload.id);

      if (messageIndex !== -1) {
        state.conversation.splice(messageIndex, 1);
      }
    },
    sourceToggle: (state, action) => {
      const source = state.sources.find((s) => s.name === action.payload.name);

      if (source) {
        source.expanded = action.payload.expanded ?? !source.expanded;
      }
    },
    reset: (state) => {
      state.status = AppStatus.Idle;
      state.sessionId = null;
      state.conversation = [];
      state.sources = [];
    },
  },
});

export const store = configureStore({
  reducer: globalSlice.reducer,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const actions = globalSlice.actions;

export type ThunkActions = {
  search: (query: string) => AppThunk;
  askQuestion: (question: string) => AppThunk;
  chat: (question: string) => AppThunk;
  abortRequest: () => AppThunk;
}

export const thunkActions: ThunkActions = {
  search: (query: string) => {
    return async function fetchSearch(dispatch, getState) {
      const state = getState();
      safeTrack('Enterprise Search Submitted', { query, sessionId: state.sessionId });
      if (state.status === AppStatus.StreamingMessage) {
        dispatch(thunkActions.abortRequest());
      }

      dispatch(actions.reset());
      dispatch(thunkActions.chat(query));
    };
  },
  askQuestion: (question: string) => {
    return async function (dispatch, getState) {
      const state = getState();
      safeTrack('Enterprise Search Submitted', { question, sessionId: state.sessionId });

      dispatch(
        actions.addMessage({
          conversation: {
            isHuman: true,
            content: question,
            id: state.conversation.length + 1,
          },
        })
      );
      dispatch(thunkActions.chat(question));
    };
  },
  chat: (question: string) => {
    return async function fetchSearch(dispatch, getState) {
      abortController = new AbortController();
      const conversationId = getState().conversation.length + 1;

      dispatch(
        actions.addMessage({
          conversation: {
            isHuman: false,
            content: '',
            id: conversationId,
          },
        })
      );
      dispatch(actions.setStatus({ status: AppStatus.StreamingMessage }));

      let countRetiresError = 0;
      let message = '';
      let usageData: { input_tokens?: number, output_tokens?: number } = {};
      const sessionId = getState().sessionId;

      await fetchEventSource(`${API_HOST}/chat`, {
        method: 'POST',
        openWhenHidden: true,
        body: JSON.stringify({
          question,
          session_id: sessionId,
        }),
        headers: getAPIHeaders(),
        signal: abortController.signal,
        async onmessage(event) {
          if (event.event === 'FatalError') {
            throw new FatalError(event.data);
          }

          if (event.data.startsWith(STREAMING_EVENTS.SESSION_ID)) {
            const sessionId = event.data.split(' ')[1].trim();
            dispatch(actions.setSessionId({ sessionId }));
          } else if (event.data.startsWith(STREAMING_EVENTS.SOURCE)) {
            const source = event.data.replace(`${STREAMING_EVENTS.SOURCE} `, '');

            try {
              if (source) {
                const parsedSource: {
                  name: string;
                  page_content: string;
                  url?: string;
                  category?: string;
                  confidence?: number;
                  updated_at?: string | null;
                } = JSON.parse(source.replaceAll('\n', ''));

                if (parsedSource.page_content && parsedSource.name) {
                  dispatch(
                    actions.addSource({
                      source: {
                        name: parsedSource.name,
                        url: parsedSource.url,
                        summary: parsedSource.page_content,
                        icon: parsedSource.category,
                        confidence: parsedSource.confidence,
                        updated_at: parsedSource.updated_at,
                      },
                    })
                  );
                }
              }
            } catch (e) {
              console.log('error', source, event.data);
              console.error(e);
            }
          } else if (event.data.startsWith(STREAMING_EVENTS.USAGE)) {
            const usageRawData = event.data.replace(`${STREAMING_EVENTS.USAGE} `, '');
            try {
              usageData = JSON.parse(usageRawData);
            } catch {
              console.error('Error parsing usage data');
            }
          } else if (event.data === STREAMING_EVENTS.DONE) {
            const sources = parseSources(message);
            safeTrack('Enterprise Search Response Received', {
              response: message.replace(/SOURCES:(.+)*/, '').substring(0, 100),
              input_tokens: usageData.input_tokens,
              output_tokens: usageData.output_tokens,
            })
            safeTrack('Enterpise Search Results Returned', { count: sources.length });
            dispatch(
              actions.setMessageSource({
                id: conversationId,
                sources,
              })
            );

            dispatch(actions.setStatus({ status: AppStatus.Done }));
          } else {
            message += JSON.parse(event.data);

            dispatch(
              actions.updateMessage({
                id: conversationId,
                content: message.replace(/SOURCES:(.+)*/, ''),
              })
            );
          }
        },
        async onopen(response) {
          if (response.ok) {
            return;
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            throw new FatalError();
          } else {
            throw new RetriableError();
          }
        },
        onerror(err) {
          if (err instanceof FatalError || countRetiresError > 3) {
            dispatch(actions.setStatus({ status: AppStatus.Error }));

            throw err;
          } else {
            countRetiresError++;
            console.error(err);
          }
        },
      });
    };
  },
  abortRequest: () => {
    return function (dispatch, getState) {
      const messages = getState().conversation;
      const lastMessage = messages[getState().conversation.length - 1];

      abortController?.abort();
      abortController = null;

      if (!lastMessage.content) {
        dispatch(
          actions.removeMessage({
            id: lastMessage.id,
          })
        );
      }
      dispatch(
        actions.setStatus({
          status: messages.length ? AppStatus.Done : AppStatus.Idle,
        })
      );
    };
  },
};

const parseSources = (message: string) => {
  message = message.replace(/"/g, '');
  const match = message.match(/SOURCES:(.+)*/);
  if (match && match[1]) {
    return match[1].split(',').map((element) => {
      return element.trim();
    });
  }
  return [];
};

export const GlobalStateProvider = ({ children }: { children: React.ReactNode}) => {
  return <Provider store={store}>{children}</Provider>;
};
