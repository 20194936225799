import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, IconButton, Button, Popover, Typography } from '@mui/material';
import { Uploads } from './types';
import { getModels, shareToEmail, getPDFExportLink, postSummarizeFromText } from '../../interactors/prompt-sandbox';
import { uploadFileSync } from '../../interactors/file';
import EmptyContent from './EmptyContent';
import { LLMModel, LlmTranscript } from '../../types/promptSandbox';
import { NotificationContext } from '../../App';
import { safeTrack } from '../../provider/Pendo';
import AttachFile from '@mui/icons-material/AttachFile';
import { useDropzone } from 'react-dropzone';
import { calculateChecksum256 } from '../../components/DocResolver/utils';
import { OCRApiDocument } from '../../types/ocr_document';
import { DocResolver, FileBox } from '../../components/DocResolver';
import { getDocumentMetadata } from '../../interactors/documents';
import ReactMarkdown from 'react-markdown';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getDocumentIdByDocumentNumber } from '../../interactors/search-api';
import Tooltip from '@mui/material/Tooltip';

const CANNOT_SUBMIT_WHLIE_DOCUMENT_IS_LOADING = 'Cannot submit when document is being uploaded or AI is generating response';
const CANNOT_SUBMIT_WITH_NO_DOCS_SUPPLIED = 'Cannot submit if there is no documents attached';

const getMessageColor = (role: string) => {
  switch (role) {
    case 'user':
      return '#11509940';

    case 'assistant':
      return 'transparent';

    case 'document':
      return 'grey';

    case 'loading_assistant':
      return 'transparent';

    case 'loading_user':
      return 'transparent';
  }
};

const getMessageAlignment = (role: string) => {
  switch (role) {
    case 'user':
      return 'flex-end';

    case 'assistant':
      return 'flex-start';

    case 'document':
      return 'flex-end';

    case 'loading_assistant':
      return 'flex-start';

    case 'loading_user':
      return 'flex-end';
  }
};

export default function Chat() {
  const [searchParams] = useSearchParams();
  const [transcript, setTranscript] = useState<LlmTranscript>([]);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [modelId, setModelId] = useState<string>('gpt-4o-mini');
  const [isLoadingAiAnswer, setIsLoadingAiAnswer] = useState<boolean>(false);
  const [isUploadingDocumentById, setIsUploadingDocumentById] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');
  const [availableModels, setAvailableModels] = useState<LLMModel[]>([]);
  const [uploads, setUploads] = useState<Uploads>({});
  const [fileNameMap, setFileNameMap] = useState<{ [key in string]: 'string' }>({});
  const [uploadedDocument, setUploadedDocument] = useState<{ name: string; size: number; state: string } | null>(null);

  const { setToastMessage } = useContext(NotificationContext);

  const anyDocumentsBeingUploaded = isUploadingDocumentById || uploadedDocument;

  useEffect(() => {
    getModels()
      .then(setAvailableModels)
      .catch((error) => {
        setToastMessage({ message: JSON.stringify(error), severity: 'error' });
      });
  }, []);

  const handleAddMessageToTranscript = (role: string, content: string, id?: string) => {
    setTranscript((prevItems) => [...prevItems, { role, content, id }]);
  };

  const handleAddDocument = (documentId: string, documentName: string) => {
    setDocumentIds((prevItems) => {
      const newDocumentIds = [...new Set([...prevItems, documentId])];
      if (newDocumentIds.length !== prevItems.length) {
        handleAddMessageToTranscript('document', documentId);
      }
      return newDocumentIds;
    });
    handleSetFileMap(documentId, documentName);
  };

  const handleSetFileMap = (docId: string, docName: string) => {
    setFileNameMap((prevMap) => ({ ...prevMap, [docId]: docName }));
  };

  const handleLlmResponse = useCallback(
    async (userMessage: string) => {
      setIsLoadingAiAnswer(true);
      const filteredTranscript = [...transcript.filter(({ role }) => ['user', 'assistant'].includes(role)), { role: 'user', content: userMessage }].map(({ role, content }) => ({ role, content }));
      const transcriptWithoutErrors = filterConsecutiveUserMessages(filteredTranscript);
      const shoudlUseInputText = !!inputText.length;
      try {
        const getResponseParams = {
          model_id: modelId,
          transcript: transcriptWithoutErrors,
          document_ids: shoudlUseInputText ? undefined : documentIds,
          input_text: shoudlUseInputText ? inputText : undefined,
        };
        const response = await postSummarizeFromText(getResponseParams);
        handleAddMessageToTranscript('assistant', response.result, response.id);
      } catch {
        handleAddMessageToTranscript('error', 'Could not generate AI response!');
      }

      setIsLoadingAiAnswer(false);
    },
    [modelId, transcript, documentIds, inputText, transcript, setIsLoadingAiAnswer, handleAddMessageToTranscript]
  );

  const handleUserMessage = (message: string) => {
    handleAddMessageToTranscript('user', message);
    handleLlmResponse(message);
  };

  const handleDropFiles = async (drops: Blob[]) => {
    try {
      // TODO: currently dropzone only allows 1 document at the time, in the future we should support multiple
      const newUploads = { ...uploads };
      const drop = drops[0];
      const checksum = await calculateChecksum256(drop);
      const name = drop.name;
      const size = drop.size;
      if (newUploads[checksum]) {
        return;
      }

      setUploadedDocument({ name, size, state: 'UPLOADING' });
      newUploads[checksum] = true;
      setUploads(newUploads);

      for await (const chunk of uploadFileSync(drop)) {
        const status = chunk.status;
        setUploadedDocument((prev) => ({ ...prev, state: status }));

        if (status === 'READY') {
          const documentId = chunk.document_id;
          const versionId = chunk.version_id;
          safeTrack('File Upload', { documentId, versionId, name, size });
          setUploadedDocument(null);
          if (status === 'READY') {
            handleAddDocument(documentId, name);
          }
        } else if (status === 'ERROR') {
          setUploadedDocument(null);
          handleAddMessageToTranscript('error', 'Could not upload the document. Please try again!');
        }
      }
    } catch {
      setUploadedDocument(null);
      handleAddMessageToTranscript('error', 'Could not upload the document. Please try again!');
    }
  };

  const handleSearchDocumentId = async (documentId: string) => {
    try {
      setIsUploadingDocumentById(true);

      handleAddMessageToTranscript('loading_user', 'Loading document...');

      const document = await getDocumentMetadata(documentId);

      if (!document?.metadata) {
        throw new Error('No document metadata');
      }

      setIsUploadingDocumentById(false);

      if (documentIds.includes(documentId)) {
        setToastMessage({ message: 'Document has been already added', severity: 'error' });
        return;
      }

      setTranscript((prevItems) => prevItems.filter((msg) => !['loading_assistant', 'loading_user'].includes(msg.role)));

      setToastMessage({ message: 'Document added', severity: 'success' });
      handleAddDocument(documentId, document.metadata.name);
    } catch {
      setIsUploadingDocumentById(false);

      setTranscript((prevItems) => prevItems.filter((msg) => !['loading_assistant', 'loading_user'].includes(msg.role)));

      setToastMessage({ message: 'Document id could not be found', severity: 'error' });
    }
  };

  useEffect(() => {
    const documentIdQuery = searchParams.get('documentId') ?? '';
    if (documentIdQuery) {
      handleSearchDocumentId(documentIdQuery);
    }
  }, []);

  const handleSearchDocumentNumber = async (documentNumber: string) => {
    const documentId = await getDocumentIdByDocumentNumber(documentNumber);
    return await handleSearchDocumentId(documentId);
  };

  const handleClearTranscript = () => {
    setDocumentIds([]);
    setTranscript([]);
  };

  const handleDeleteDocument = (documentId: string) => {
    const newTranscript = transcript.filter((item) => item.content !== documentId);
    const newDocumentIds = documentIds.filter((id) => id !== documentId);

    setTranscript(newTranscript);
    setDocumentIds(newDocumentIds);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} height="calc(100vh - 64px)" padding={'32px'}>
      <Settings setModel={setModelId} model={modelId} availableModels={availableModels} clearTranscript={handleClearTranscript} disableClear={!transcript.length} />
      <Transcript
        transcript={transcript}
        isLoading={isLoadingAiAnswer}
        uploadedDocument={uploadedDocument}
        fileNameMap={fileNameMap}
        anyDocumentsBeingUploaded={anyDocumentsBeingUploaded}
        deleteDocument={handleDeleteDocument}
      />
      <UserInput
        sendMessage={handleUserMessage}
        isLoading={isLoadingAiAnswer || anyDocumentsBeingUploaded}
        isQuestionInputDisabled={!documentIds.length && !inputText.length}
        searchDocument={handleSearchDocumentNumber}
        inputText={inputText}
        setInputText={setInputText}
        uploadFiles={handleDropFiles}
      />
    </Box>
  );
}

function Transcript({
  transcript,
  isLoading,
  uploadedDocument,
  fileNameMap,
  anyDocumentsBeingUploaded,
  deleteDocument,
}: {
  transcript: LlmTranscript;
  isLoading: boolean;
  resolveDocument: (checksum: string, document: OCRApiDocument) => void;
  uploadedDocument: { name: string; size: number } | null;
  anyDocumentsBeingUploaded: boolean;
  fileNameMap: { [key in string]: string };
  deleteDocument: (documentId: string) => undefined;
}) {
  const transcriptRef = useRef<HTMLDivElement>(null);

  // Scroll to the bottom when a new message is added
  useEffect(() => {
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [transcript, anyDocumentsBeingUploaded]);

  if (!transcript.length && !anyDocumentsBeingUploaded) {
    return <EmptyContent />;
  }

  const adjustedTranscript = transcript.map((message) => {
    if (message.role !== 'document') {
      return message;
    }

    return { ...message, content: fileNameMap[message.content], documentId: message.content };
  });

  return (
    <Box
      ref={transcriptRef}
      height={'100%'}
      overflow="scroll"
      display="flex"
      flexDirection="column"
      padding="16px"
      sx={(theme) => ({ backgroundColor: theme.palette.background.secondary, borderRadius: '6px' })}
    >
      {adjustedTranscript.map(({ content, role, id, documentId }, key) => (
        <Message key={key} content={content} role={role} id={id} documentId={documentId} deleteDocument={deleteDocument} />
      ))}
      {uploadedDocument && (
        <Box display="flex" justifyContent={'flex-end'} marginBottom={'8px'}>
          <DocResolver name={uploadedDocument.name} size={uploadedDocument.size} state={uploadedDocument.state} />
        </Box>
      )}
      {isLoading && <Message content={'Loading...'} role={'loading_assistant'} />}
    </Box>
  );
}

function Message({ content, role, id, documentId, deleteDocument }: { content: string; role: string; id?: string; documentId?: string; deleteDocument: (documentId: string) => undefined }) {
  const color = getMessageColor(role);
  const alignment = getMessageAlignment(role);

  if (role === 'document') {
    return (
      <Box display="flex" justifyContent={alignment} marginBottom={'8px'}>
        <FileBox name={content} documentId={documentId} deleteDocument={deleteDocument} />
      </Box>
    );
  }

  if (role === 'loading_assistant') {
    return (
      <Box display="flex" justifyContent={alignment} marginBottom={'8px'} maxWidth={'100%'}>
        <Box borderRadius={'5px'} padding={'0 10px'} bgcolor={color} width={'fit-content'} display="flex" maxWidth="90%">
          <Box display="flex" alignItems="center">
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    );
  }

  if (role === 'loading_user') {
    return (
      <Box display="flex" justifyContent={alignment} marginBottom={'8px'} maxWidth={'100%'}>
        <Box borderRadius={'5px'} padding={'0 10px'} bgcolor={color} width={'fit-content'} display="flex" maxWidth="90%">
          <Box display="flex" alignItems="center">
            <CircularProgress />
            <Box marginLeft={'8px'}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  if (role === 'error') {
    return (
      <Box display="flex" justifyContent={alignment} marginBottom={'8px'} maxWidth={'100%'}>
        <Box borderRadius={'5px'} padding={'0 10px'} bgcolor={color} width={'fit-content'} display="flex" maxWidth="90%">
          <Box display="flex" alignItems={'center'} marginRight="8px">
            <ErrorIcon />
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <ReactMarkdown>{content}</ReactMarkdown>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent={alignment} marginBottom={'8px'} maxWidth={'100%'}>
      <Box borderRadius={'5px'} padding={'0 10px'} bgcolor={color} width={'fit-content'} display="flex" maxWidth="90%">
        <Box display="flex" alignItems="center">
          <Box>
            <ReactMarkdown>{content}</ReactMarkdown>
          </Box>
        </Box>
        {role === 'assistant' ? <Share content={content} id={id} /> : null}
      </Box>
    </Box>
  );
}

function UserInput({
  sendMessage,
  isLoading,
  isQuestionInputDisabled,
  searchDocument,
  uploadFiles,
}: {
  sendMessage: (message: string) => void;
  isLoading: boolean;
  isQuestionInputDisabled: boolean;
  searchDocument: (documentId: string) => void;
  inputText: string;
  setInputText: (text: string) => void;
  uploadFiles: (blobs: Blob[]) => void;
}) {
  const [inputValue, setInputValue] = useState('');
  const [documentId, setDocumentId] = useState('');

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadFiles,
    accept: { 'application/pdf': ['.pdf'] },
    maxFiles: 1,
  });
  const onClick = getRootProps().onClick;
  const rootProps = {
    ...getRootProps(),
    onClick: () => {},
  };

  const handleUserTextKeyPress = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isLoading || isQuestionInputDisabled || !inputValue.length) {
        if (isQuestionInputDisabled && inputValue.length) {
          setToastMessage({ severity: 'warning', message: CANNOT_SUBMIT_WITH_NO_DOCS_SUPPLIED });
        } else if (isLoading && inputValue.length) {
          setToastMessage({ severity: 'warning', message: CANNOT_SUBMIT_WHLIE_DOCUMENT_IS_LOADING });
        }
        return;
      }

      sendMessage(inputValue);
      setInputValue('');
    },
    [inputValue, sendMessage, setInputValue, isQuestionInputDisabled, isLoading]
  );

  const handleDocumentIdKeyPress = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isLoading || !documentId.length) {
        if (isLoading && documentId.length) {
          setToastMessage({ severity: 'warning', message: CANNOT_SUBMIT_WHLIE_DOCUMENT_IS_LOADING });
        }
        return;
      }

      searchDocument(documentId);
      setDocumentId('');
    },
    [searchDocument, documentId, isLoading]
  );

  return (
    <Box
      marginTop={'8px'}
      sx={{
        borderStyle: 'dashed',
        borderWidth: '3px',
        borderColor: isDragActive ? 'black' : 'transparent',
      }}
      {...rootProps}
    >
      <input {...getInputProps()} multiple />
      <Box
        marginBottom="8px"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.icon,
          borderRadius: '6px',
        })}
      >
        <form onSubmit={handleUserTextKeyPress}>
          <TextField
            value={inputValue}
            variant="outlined"
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your prompt here..."
            InputLabelProps={{ shrink: true }}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton disabled={!inputValue.length} onClick={handleUserTextKeyPress} edge="end" sx={{ marginRight: '4px' }}>
                  <ArrowForwardIcon />
                </IconButton>
              ),
            }}
          />
        </form>
      </Box>
      <Box display="flex" alignItems={'center'}>
        <Box mr={'8px'}>
          <IconButton onClick={onClick}>
            <AttachFile />
          </IconButton>
        </Box>
        <Box width="100%">
          <form onSubmit={handleDocumentIdKeyPress}>
            <TextField
              value={documentId}
              onChange={(e) => setDocumentId(e.target.value)}
              placeholder="Enter Document Number"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={
                <Box display="flex" alignItems="center" paddingRight="20px">
                  Document Number
                </Box>
              }
              InputProps={{
                endAdornment: (
                  <IconButton disabled={!documentId.length} onClick={handleDocumentIdKeyPress} edge="end" sx={{ marginRight: '4px' }}>
                    <ArrowForwardIcon />
                  </IconButton>
                ),
              }}
            />
          </form>
        </Box>
      </Box>
    </Box>
  );
}

function Settings({
  availableModels,
  setModel,
  model,
  clearTranscript,
  disableClear,
}: {
  availableModels: LLMModel[];
  setModel: (modelId: string) => void;
  model: string;
  clearTranscript: () => undefined;
  disableClear: boolean;
}) {
  return (
    <Box marginBottom={'4px'}>
      <Box>
        <Typography sx={{ fontWeight: 700, fontSize: '32px' }}>AI Doc Chat</Typography>
      </Box>
      <Box display="flex" justifyContent={'flex-end'} marginBottom={'8px'}>
        <Box>
          <Button
            onClick={clearTranscript}
            disabled={disableClear}
            sx={{
              marginRight: '8px',
              fontSize: '16px',
              fontWeight: '400',
              textTransform: 'none',
              marginBottom: '4px',
            }}
          >
            Clear chat
          </Button>
          <Select
            value={model}
            onChange={(event) => setModel(event.target.value)}
            variant="standard"
            disableUnderline
            displayEmpty
            renderValue={() => 'Additional settings'}
            sx={{
              '& .MuiSelect-select': {
                padding: 0,
                fontSize: '16px',
                fontWeight: '400',
                color: 'inherit',
              },
              '& .MuiSelect-root': {
                border: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {availableModels.map(({ model_id, name }) => (
              <MenuItem key={model_id} value={model_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
}

function Share({ id, content }: { id?: string; content?: string }) {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [email, setEmail] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);

  const { setToastMessage } = useContext(NotificationContext);

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const handleChangeEmail = (ev: ChangeEvent<HTMLInputElement>) => {
    setEmail(ev?.target?.value);
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(content ?? '');
    setToastMessage({ severity: 'success', message: 'Copied!' });
  };

  const handleSendEmail = async () => {
    if (!email || !id) {
      return setToastMessage({
        message: 'Valid email missing',
        severity: 'error',
      });
    }
    setEmailLoading(true);
    try {
      await shareToEmail(email, id);
      setToastMessage({
        message: 'Email sent!',
        severity: 'success',
      });
    } catch (err) {
      setToastMessage({
        message: JSON.stringify(err),
        severity: 'error',
      });
    } finally {
      setEmailLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    if (!id) return;
    const { link } = await getPDFExportLink(id);
    const anchor = document.createElement('a');
    anchor.download = `Prompt Sandbox - ${new Date().toISOString()}.pdf`;
    anchor.href = link;
    anchor.click();
    anchor.remove();
  };

  return (
    <Box display="flex" marginLeft={'4px'} marginTop={'12px'}>
      <Box>
        <IconButton onClick={handleOpenPopover}>
          <IosShareIcon />
        </IconButton>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={popoverAnchor}
          open={!!popoverAnchor}
          onClose={handleClosePopover}
        >
          <Box sx={{ p: 2 }}>
            <TextField placeholder="Email" variant="standard" onChange={handleChangeEmail} value={email} />
            <Tooltip title="Email response report" arrow>
              <Button color="info" onClick={handleSendEmail} disabled={emailLoading}>
                <SendIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Copy response to clipboard" arrow>
              <Button color="info" onClick={handleCopyText}>
                <ContentCopyIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Download response report" arrow>
              <Button color="info" onClick={handleDownloadPDF}>
                <PictureAsPdfIcon />
              </Button>
            </Tooltip>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}

const filterConsecutiveUserMessages = (messages: any[]) => {
  let result = [];
  let i = 0;

  while (i < messages.length) {
    const current = messages[i];
    const next = messages[i + 1];

    if (current.role === 'user' && next && next.role === 'user') {
      // Skip to the next message
      i++;
    } else {
      result.push(current);
      i++;
    }
  }

  return result;
};
