import React, { useContext, useEffect } from 'react';
import { injectPendoScript } from './inject-pendo-script';
import { initializePendo } from './initialize-pendo';
import { AuthContext } from '../../App';

declare global {
  interface Window {
    pendo?: {
      initialize: (initializeData?: object) => object;
      track: (event: string, params?: object) => void;
      isReady: () => boolean;
    };
  }
}

export const safeTrack = (event: string, eventParams?: object) => {
  if (!window.pendo) return;
  window.pendo.track(event, eventParams);
};

export const PendoProvider = ({ children }: { children: React.ReactNode }) => {
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    injectPendoScript();
  }, []);

  useEffect(() => {
    if (userInfo) {
      initializePendo(
        {
          id: userInfo['email'],
          email: userInfo['email'],
          full_name: `${userInfo['given_name']} ${userInfo['family_name']}`,
        },
        {
          id: userInfo['email'],
          cognitoId: userInfo['sub'],
          name: userInfo['cognito:groups'].join(', '),
        }
      );
    }
  }, [userInfo]);

  return <>{children}</>;
};
